<template>
  <div>
    <van-form>
      <van-field v-model="username" label="用户名" placeholder="请输入用户名" />
      <van-field v-model="password" type="password" label="密码" />
    </van-form>
    <van-button @click="handleLogin" type="primary">登录</van-button>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { oauth } from "@/api/user";
import { reactive, toRefs } from "vue";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const data = reactive({
      username: "",
      password: "",
    });
    const handleLogin = () => {
      let param = {
        username: data.username,
        password: data.password,
        clientId: "system",
        clientSecret: "system",
        scope: "app",
        grantType: "password",
      };
      oauth(param)
        .then((data) => {
          console.log(data);
          store.commit("SET_ACCESS_TOKEN", data.access_token);
          store.commit("SET_REFRESH_TOKEN", data.refresh_token);
          router.replace("/");
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return {
      handleLogin,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped></style>
